import "./HamburgMenu.scss";
import React, { useState, useEffect } from "react";
import CloseIcon from "../../assets/icons/CloseButton.svg";
import HamburgerMenuLines from "../../assets/icons/HamburgMenuLines.svg";
import ResumePDF from "../../assets/images/AnthonyQuispeResume.pdf";
import { Link } from "react-router-dom";

export default function HamburgMenu({ close }) {
  const [activeSection, setActiveSection] = useState("home");

  const handleResumeClick = () => {
    const link = document.createElement("a");
    link.href = ResumePDF;
    link.download = "Anthony Quispe resume.pdf";
    link.click();
  };

  const handleSetActiveSection = (section) => {
    setActiveSection(section);
    close();
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll("section");
    let currentSection = "home";

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 100;
      if (window.scrollY >= sectionTop) {
        currentSection = section.getAttribute("id");
      }
    });

    setActiveSection(currentSection);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="hamburg-menu">
      <ul className="hamburg-menu__list">
        <button className="hamburg-menu__button" onClick={close}>
          <img src={CloseIcon} className="hamburg-menu__button-icon" />
        </button>
        <a
          herf="#resume"
          onClick={handleResumeClick}
          className="hamburg-menu__list-item-link hamburg-menu__list-item-link--display"
        >
          <li className="hamburg-menu__list-item  hamburg-menu__list-item--purple">
            Resume
          </li>
        </a>
        <a
          href="#home"
          className="hamburg-menu__list-item-link"
          onClick={() => handleSetActiveSection("home")}
        >
          <li
            className={`hamburg-menu__list-item ${
              activeSection === "home" ? "hamburg-menu__list-item--blue" : ""
            }`}
          >
            Home
          </li>
        </a>
        <a
          href="#about"
          className="hamburg-menu__list-item-link"
          onClick={() => handleSetActiveSection("about")}
        >
          <li
            className={`hamburg-menu__list-item ${
              activeSection === "about" ? "hamburg-menu__list-item--blue" : ""
            }`}
          >
            About
          </li>
        </a>
        <a
          href={"#projects"}
          className="hamburg-menu__list-item-link"
          onClick={() => handleSetActiveSection("projects")}
        >
          <li
            className={`hamburg-menu__list-item ${
              activeSection === "projects"
                ? "hamburg-menu__list-item--blue"
                : ""
            }`}
          >
            Projects
          </li>
        </a>
        <a
          href="#skills"
          className="hamburg-menu__list-item-link"
          onClick={() => handleSetActiveSection("skills")}
        >
          <li
            className={`hamburg-menu__list-item ${
              activeSection === "skills" ? "hamburg-menu__list-item--blue" : ""
            }`}
          >
            Skills
          </li>
        </a>
        <a
          href="#work"
          className="hamburg-menu__list-item-link"
          onClick={() => handleSetActiveSection("work")}
        >
          <li
            className={`hamburg-menu__list-item ${
              activeSection === "work" ? "hamburg-menu__list-item--blue" : ""
            }`}
          >
            Work
          </li>
        </a>
        <a
          href="#connect"
          className="hamburg-menu__list-item-link"
          onClick={() => handleSetActiveSection("connect")}
        >
          <li
            className={`hamburg-menu__list-item ${
              activeSection === "connect" ? "hamburg-menu__list-item--blue" : ""
            }`}
          >
            Connect
          </li>
        </a>

        {/* <Link
          to="/archive"
          className="hamburg-menu__list-item-link hamburg-menu__list-item-link--display"
        >
          <li className="hamburg-menu__list-item  ">Archive</li>
        </Link> */}
      </ul>
      <button className="hamburg-menu__button-close" onClick={close}>
        <img
          src={HamburgerMenuLines}
          alt="Menu"
          className="hamburg-menu__button-close-img"
        />
      </button>
    </div>
  );
}
