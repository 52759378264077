import "./WorkExperienceSection.scss";

export default function WorkExperienceSection() {
  const Jobs = [
    {
      Name: "MakeItMVp",
      Date: "Jun 2023 - Present",
      Role: "Full Stack Developer",
      Description:
        "As a skilled and experienced Software Developer at MakeItMVP, I play a pivotal role in driving the success and growth of our platform. With a strong focus on innovation and user experience, I am responsible for designing, developing, and maintaining various features and tools that enhance our platform's functionality and performance.",
    },
    {
      Name: "Conviva",
      Date: "APR 2022 - Present",
      Role: "Help Desk Technician",
      Description:
        "In my dual role as an Onboarding Process Owner and Help Desk Technician, my prowess shines through in delivering exceptional support and guidance to my team, ensuring a streamlined and efficient onboarding process.",
    },
    {
      Name: "BrainStation",
      Date: "JUN 2023 - Sep 2023",
      Role: "Software Engineering Teaching Assistant",
      Description:
        "Teaching assistant for full-time students attending BrainStation's Full Stack Software Engineer Program. The technologies learned as part of the program include JavaScript (ES6), Node, React, Express, Node, MySQL, Deployment (Heroku), Testing (Jest) and Agile Development, Github Pilot.",
    },
    {
      Name: "Others",
      Date: "FEB 2018 - APR 2022",
      Role: "Other IT Roles",
      Description:
        "Over four years of experience as a Technical Support and IT Specialist, administering Office 365, Azure, Five9, and Active Directory to enhance collaboration, security, and system performance. Delivered comprehensive Tier 1-3 support for proprietary software, CRM, and ERP systems, efficiently resolving technical issues to minimize downtime and improve user satisfaction. Skilled in managing Salesforce, Citrix, and network operations, including proactive security monitoring and troubleshooting, ensuring robust system reliability. Proven expertise in utilizing ticketing systems to streamline support across departments and executing seamless hardware and software setups, migrations, and configurations to boost productivity and operational efficiency.",
    },
  ];

  return (
    <section className="work-exp" id="work">
      <div className="work-exp__container">
        <div className="work-exp__container-top">
          <h2 className="work-exp__title">
            <span className="work-exp__title--purple">Work</span> Experience
          </h2>
          <hr className="work-exp__hr" />
        </div>
        <div className="work-exp__container-bottom">
          {Jobs.map((job, index) => (
            <div key={index} className="work-exp__job">
              <div className="work-exp__job-container">
                <p className="work-exp__job-name">{job.Name}</p>
                <p className="work-exp__job-date">{job.Date}</p>
              </div>
              <div className="work-exp__job-container">
                <h3 className="work-exp__job-role">{job.Role}</h3>
                <p className="work-exp__job-description">{job.Description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
