import "./HeroSection.scss";
import React, { useState, useEffect } from "react";

export default function HeroSection() {
  const titles = [
    "Full Stack Software Dev",
    "Back End Software Dev",
    "Front End Software Dev",
  ];
  const [displayTitle, setDisplayedTitle] = useState("");
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [charIndex, setCharIndex] = useState(0);
  const typingSpeed = 200;
  const deletingSpeed = 100;
  const pauseDuration = 2000;

  useEffect(() => {
    let typingTimeout;

    const handleTyping = () => {
      const currentTitle = titles[currentTitleIndex];

      if (isDeleting) {
        // Deleting logic
        if (charIndex > 0) {
          setDisplayedTitle(currentTitle.slice(0, charIndex - 1));
          setCharIndex(charIndex - 1);
          typingTimeout = setTimeout(handleTyping, deletingSpeed);
        } else {
          setIsDeleting(false);
          setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
        }
      } else {
        // Typing logic
        if (charIndex < currentTitle.length) {
          setDisplayedTitle(currentTitle.slice(0, charIndex + 1));
          setCharIndex(charIndex + 1);
          typingTimeout = setTimeout(handleTyping, typingSpeed);
        } else {
          // Pause after fully typing the title
          setTimeout(() => setIsDeleting(true), pauseDuration);
        }
      }
    };

    typingTimeout = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(typingTimeout);
  }, [charIndex, isDeleting, currentTitleIndex]);

  return (
    <section className="hero-section" id="home">
      <div className="hero-section__container">
        <div className="hero-section__img-container" />
        <div className="hero-section__text-container">
          <h1 className="hero-section__text">{displayTitle}</h1>
        </div>
      </div>
    </section>
  );
}
