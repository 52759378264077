import "./ConnectSection.scss";
import emailjs from "@emailjs/browser";

export default function ConnectSection() {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dikqywc",
        "template_2mxbnnx",
        e.target,
        "CKq4KrrxGsOe21VTd"
      )
      .then(
        (result) => {
          alert("Message Sent Successfully!");
          e.target.reset();
        },
        (error) => {
          alert("Failed to send the message. Please try again.");
        }
      );
  };

  return (
    <section className="connect-section" id="connect">
      <div className="connect-section__container">
        <h2 className="connect-section__title">Connect With Me</h2>
        <hr className="connect-section__hr" />
        <form className="connect-section__form" onSubmit={sendEmail}>
          <select
            name="subject"
            id="subject"
            defaultValue=""
            className="connect-section__select"
            required
          >
            <option value="" disabled className="connect-section__option">
              Subject
            </option>
            <option
              value="Frontend Inquire"
              className="connect-section__option"
            >
              Frontend Development
            </option>
            <option value="Backend Inquire" className="connect-section__option">
              Backend Development
            </option>
            <option value="Startup Inquire" className="connect-section__option">
              StartUp
            </option>
            <option
              value="Digital Consultant"
              className="connect-section__option"
            >
              Digital Consultant
            </option>
            <option value="Coffee Talk" className="connect-section__option">
              Coffee Talk
            </option>
          </select>
          <input
            placeholder="Name"
            name="user_name"
            className="connect-section__input"
            required
            id="name"
            autoComplete="name"
          />
          <input
            placeholder="Email"
            name="user_email"
            className="connect-section__input"
            required
            id="email"
            autoComplete="email"
          />
          <textarea
            placeholder="Message"
            name="message"
            className="connect-section__textarea"
            required
            id="comment"
          />
          <button type="submit" className="connect-section__submit">
            Submit
          </button>
        </form>
      </div>
    </section>
  );
}
