import "./AboutSection.scss";
import AnthonyIcon from "../../assets/images/Me-Desktop.png";
import React, { useEffect, useState } from "react";

export default function AboutSection() {
  return (
    <section className="about-section" id="about">
      <div className="about-section__container">
        <div className="about-section__top-container">
          <h2 className="about-section__title">About Me</h2>
          <hr className="about-section__hr" />
        </div>
        <div className="about-section__bottom-container">
          <div className="about-section__left-container">
            <div className="about-section__img-container">
              <img
                src={AnthonyIcon}
                className="about-section__img"
                alt="Animated version of me"
              />
            </div>
          </div>
          <div className="about-section__right-container">
            <div className="about-section__right-container-text">
              <p className="about-section__body">
                As a{" "}
                <span className="about-section__body--blue">
                  software developer{" "}
                </span>{" "}
                passionate about technology, I specialize in creating web apps
                using React, JavaScript, Sass, and Firebase. I’ve always been
                inspired by technology and video games, which led me to build
                practical applications that make everyday life more enjoyable
                and efficient. From helping others learn to code with my web-app
                Level-up Code to developing a local pickup game platform, my
                goal is to create tools that make a real impact.
              </p>
              <p className="about-section__body about-section__body--blue">
                Skills & Experience
              </p>
              <ul className="about-section__list">
                <li className="about-section__list-item">
                  Proficient in React, JavaScript, Sass, and Firebase
                </li>
                <li className="about-section__list-item">
                  Creator of Level-up Code, a fun coding learning platform
                </li>
                <li className="about-section__list-item">
                  Creator of Swoosh, a social media app for local pickup games,
                </li>
                <li className="about-section__list-item">
                  Focus on writing clean, reusable code that can scale
                </li>
                <li className="about-section__list-item">
                  Enjoy teaching and collaborating with teams to build
                  innovative solutions
                </li>
              </ul>
              <p className="about-section__body">
                I’m excited to collaborate with startups and work on projects
                that help people. I’m particularly focused on growing Level-up
                Code to 1,000 users and expanding its impact. In my spare time,
                I enjoy video games, basketball, and creating YouTube content to
                teach coding.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
