import "./SkillSection.scss";

export default function SkillSection() {
  const skills = {
    "Front End": [
      "React",
      "CSS",
      "SASS",
      "TailWind",
      "HTML5",
      "Vanilla JavaScript",
      "DOM Manipulation",
    ],
    "Back End": [
      "Node.js",
      "Express.js",
      "Firestore",
      "MySQL",
      "Cloud Functions",
      "User Authentication",
      "Rest APIs",
    ],
    "misc.": [
      "Firebase",
      "Vercel",
      "GitHub",
      "GitLab",
      "Jest",
      "Agile Methodology",
    ],
  };

  return (
    <section className="skill-section" id="skills">
      <div className="skill-section__container">
        <div className="skill-section__top-container">
          <h2 className="skill-section__title">Skills</h2>
          <hr className="skill-section__hr" />{" "}
        </div>
        {Object.keys(skills).map((category) => (
          <div key={category} className="skill-section__bottom-container">
            <h3 className="skill-section__list-title">
              {category.charAt(0).toUpperCase() +
                category.slice(1).replace(/([A-Z])/g, " $1")}{" "}
            </h3>
            <ul className="skill-section__list">
              {skills[category].map((skill) => (
                <li key={skill} className="skill-section__list-item">
                  {skill}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
}
