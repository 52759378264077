import "./HomePage.scss";
import Header from "../../components/Header/Header";
import HeroSection from "../../components/HeroSection/HeroSection";
import AboutSection from "../../components/AboutSection/AboutSection";
import ProjectSection from "../../components/ProjectSection/ProjectSection";
import SkillSection from "../../components/SkillSection/SkillSection";
import ConnectSection from "../../components/ConnectSection/ConnectSection";
import WorkExperienceSection from "../../components/WorkExperienceSection/WorkExperienceSection";

export default function HomePage() {
  return (
    <div className="home-page">
      <Header />
      <HeroSection />
      <AboutSection />
      <ProjectSection />
      <SkillSection />
      <WorkExperienceSection />
      <ConnectSection />
    </div>
  );
}
