import "./ProjectSection.scss";
import React from "react";
import EmberEarth from "../../assets/images/E&EPhoneMockup.png";
import RightArrow from "../../assets/icons/ArrowRightConer.svg";
import Swooshmockup from "../../assets/images/Swooshmockup.png";
import LevelUpCodeMockup from "../../assets/images/LevelUpCodeMockup.png";

const Projects = [
  {
    title: "Ember+Earth ",
    img: EmberEarth,
    description:
      "I built Ember+Earth, a natural products e-commerce site with a fall-inspired aesthetic. The site features a product catalog, responsive design, highlighting my ability to create functional, visually appealing web apps.",
    href: "https://emberandearth-d3889.web.app/home",
  },
  {
    title: "Swoosh ",
    img: Swooshmockup,
    description:
      "Created a web app for pick up games / social media, utilizing React.js for the front end, Firebase for real-time database management, and Sass for custom styling. Designed the user interface and experience using Figma to deliver an intuitive and visually appealing platform.",
    href: "https://swoosh-miami.web.app",
  },
  {
    title: "Level-Up Code ",
    img: LevelUpCodeMockup,
    description:
      "Created a web app for teaching coding, utilizing React.js for the front end, Firebase for real-time database management, and Sass for custom styling. Designed the user interface and experience using Photoshop, Figma, Canva, and Illustrator to deliver an intuitive and visually appealing platform.",
    href: "https://levelup-code.web.app/",
  },
];
export default function ProjectSection() {
  return (
    <section className="project-section" id="projects">
      <div className="project__containers">
        <div className="project__container-top">
          <h2 className="project-section__title">Projects</h2>
          <hr className="project-section__hr" />
        </div>
        <div className="project__container-bottom">
          {Projects.map((project, index) => (
            <div key={index} className="project__container">
              <img
                className="project__img"
                src={project.img}
                alt={project.title}
              />
              <a className="project__text-container" href={project.href}>
                <h3 className="project__title">{project.title}</h3>
                <p className="project__description">{project.description}</p>
                <img
                  className="project__link-arrow"
                  src={RightArrow}
                  alt="project right arrow link"
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
