import React, { useState, useEffect } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import HamburgerMenuLines from "../../assets/icons/HamburgMenuLines.svg";
import "../HamburgMenu/HamburgMenu";
import HamburgMenu from "../HamburgMenu/HamburgMenu";
import SocialIcon from "../../assets/icons/SocialIcon.svg";

export default function Header() {
  const name = "Anthony Quispe";
  const [displayedName, setDisplayedName] = useState("");
  const typingSpeed = 300; // Speed of typing in milliseconds
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [activeSection, setActiveSection] = useState("home"); // State to track the active section

  useEffect(() => {
    let index = 0; // Initialize index for the name
    let typingInterval;

    const typeName = () => {
      if (index < name.length) {
        setDisplayedName(name.slice(0, index + 1)); // Display characters up to the current index
        index++;
      } else {
        clearInterval(typingInterval);
      }
    };

    // Start typing effect
    typingInterval = setInterval(typeName, typingSpeed);

    // Cleanup on unmount
    return () => clearInterval(typingInterval);
  }, [name]); // Dependency on name

  const handleHamburgerButton = () => {
    setShowHamburgerMenu(!showHamburgerMenu);
  };

  const handleSetActiveSection = (section) => {
    setActiveSection(section);
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll("section");
    let currentSection = "home";

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 100;
      if (window.scrollY >= sectionTop) {
        currentSection = section.getAttribute("id");
      }
    });

    setActiveSection(currentSection);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="header">
      <nav className="header__nav">
        <Link className="header__name" to={"/"}>
          <img src={SocialIcon} className="header__img" />
          {displayedName}
        </Link>
        <div className="header__right-container">
          <ul className="header__list">
            <a
              href="#home"
              className="header__list-link"
              onClick={() => handleSetActiveSection("home")}
            >
              <li
                className={`header__list-section ${
                  activeSection === "home" ? "header__list-section--blue" : ""
                }`}
              >
                Home
              </li>
            </a>
            <a
              href="#about"
              className="header__list-link"
              onClick={() => handleSetActiveSection("about")}
            >
              <li
                className={`header__list-section ${
                  activeSection === "about" ? "header__list-section--blue" : ""
                }`}
              >
                About
              </li>
            </a>
            <a
              href="#projects"
              className="header__list-link"
              onClick={() => handleSetActiveSection("projects")}
            >
              <li
                className={`header__list-section ${
                  activeSection === "projects"
                    ? "header__list-section--blue"
                    : ""
                }`}
              >
                Projects
              </li>
            </a>
            <a
              href="#skills"
              className="header__list-link"
              onClick={() => handleSetActiveSection("skills")}
            >
              <li
                className={`header__list-section ${
                  activeSection === "skills" ? "header__list-section--blue" : ""
                }`}
              >
                Skills
              </li>
            </a>
            <a
              href="#work"
              className="header__list-link"
              onClick={() => handleSetActiveSection("work")}
            >
              <li
                className={`header__list-section ${
                  activeSection === "work" ? "header__list-section--blue" : ""
                }`}
              >
                Work
              </li>
            </a>
            <a
              href="#connect"
              className="header__list-link"
              onClick={() => handleSetActiveSection("connect")}
            >
              <li
                className={`header__list-section ${
                  activeSection === "connect"
                    ? "header__list-section--blue"
                    : ""
                }`}
              >
                Connect
              </li>
            </a>
          </ul>
          <button className="header__menu" onClick={handleHamburgerButton}>
            <img
              src={HamburgerMenuLines}
              alt="Menu"
              className="header__menu-img"
            />
          </button>
        </div>
      </nav>
      {showHamburgerMenu && <HamburgMenu close={handleHamburgerButton} />}
    </header>
  );
}
